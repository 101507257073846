import { render, staticRenderFns } from "./inspectParticulars.vue?vue&type=template&id=bf5ca7ca&scoped=true&"
import script from "./inspectParticulars.js?vue&type=script&lang=js&"
export * from "./inspectParticulars.js?vue&type=script&lang=js&"
import style0 from "./inspectParticulars.less?vue&type=style&index=0&id=bf5ca7ca&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf5ca7ca",
  null
  
)

export default component.exports