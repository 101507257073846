
export default {
	name: 'inspectParticulars',
	data() {
		return {
			sex: '',
			newEdit: false,
			projectName: '', //标题
			departmentName: '', //科室
			diseaseName: '', //适应症
			startTime: '',
			refStatistics: [],
			particularsAlert: false,
			alertList: [],//弹框信息
			alertDate: '',//弹框完成日期
			alertName: '',//弹框监查类型
			crcUserList: [],//
		}
	},
	filters: {
		appointmentTimeFilter(time) {
			if (time == 1) {
				time = '上午'
			} else if (time == 2) {
				time = '下午'
			} else {
				time = '全天'
			}
			return time
		},
		stateFilter(state) {
			if (state == '0') {
				state = '代办'
			} else if (state == '1') {
				state = '待核实'
			} else if (state == '2') {
				state = '核实通过'
			} else if (state == '3') {
				state = '核实未通过'
			} else {
				state = '取消'
			}
			return state
		}
	},
	created() {
		this.token = this.getCookie('token')
		this.type = this.getCookie('type')
		this.getProPar()
	},

	methods: {
		getProPar() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/monitoringDetails', {
				taskId: _this.$route.params.id
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						_this.projectName = response.data.data.projectName;
						_this.departmentName = response.data.data.departmentName;
						_this.diseaseName = response.data.data.diseaseName;
						_this.startTime = response.data.data.startTime;
						_this.endTime = response.data.data.endTime;
						_this.refStatistics = response.data.data.mapList;
						_this.crcUserList = response.data.data.userList;
					} else if (response.data.code == 401) {
						_this.$router.push('/')
					}
				})
		},
		taskMonitorDetailed(index, list) {
			var _this = this;
			_this.particularsAlert = true;
			_this.$http.post(_this.craUrl + '/taskMonitorDetailed', {
				monitorType: _this.$route.params.id,
				taskMonitorId: list[index].taskMonitorId
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						_this.alertList = response.data.data;
						_this.alertName = list[index].monitorType
						_this.alertDate = _this.alertList.appointmentList[_this.alertList.appointmentList.length - 1].appointmentDate;
						//						console.log(_this.alertList.appointmentList[_this.alertList.appointmentList.length-1])
					}
				})
		},
		no() {
			//				this.removeItemIndex = "";
			this.particularsAlert = false;
		},
		tableRowClassName({
			row,
			rowIndex
		}) {
			if (rowIndex === 1) {
				return "warning-row";
			} else if (rowIndex === 3) {
				return "success-row";
			}
			return "";
		},
		toPl() {
			this.$router.go(-1)
		},
	}
}
